import { PublicConfigurations } from "../redux/reducers/public-configurations/public-configurations.reducer";
import LocalStorageWrapper, { LocalStorageItem } from "./assets/utils/LocalStorageWrapper";

export const getAppName = () => {
  const publicConfigurations: PublicConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.PUBLIC_CONFIGURATIONS)
  return publicConfigurations?.app?.name
}

export const getLogoUrl = () => {
  const publicConfigurations: PublicConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.PUBLIC_CONFIGURATIONS)
  return publicConfigurations?.cdn.logoUrl
};

export const getLogoDarkBackgroundUrl = () => {
  const publicConfigurations: PublicConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.PUBLIC_CONFIGURATIONS)
  return publicConfigurations?.cdn.logoDarkBackgroundUrl
};

export const getFaviconUrl = () => {
  const publicConfigurations: PublicConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.PUBLIC_CONFIGURATIONS)
  return publicConfigurations?.cdn.faviconUrl
};

export const getBiometryConfig = () => {
  const publicConfigurations: PublicConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.PUBLIC_CONFIGURATIONS)
  return publicConfigurations?.biometry
}