import { CameraOpener, SDKEnvironmentTypes, SelfieCameraTypes, UnicoCheckBuilder, UnicoConfig, UnicoThemeBuilder } from 'unico-webframe'
import {  getBiometryConfig } from '../../common/AssetsHelper'
import {UnicoSDKConfig} from '../../redux/reducers/public-configurations/public-configurations.reducer'

class UnicoSDKService {
  camera: Promise<CameraOpener> | undefined
  constructor() {
    const config = getBiometryConfig()

    const unicoConfig = new UnicoConfig()
      .setHostname(config.unico.sdkConfig.hostname)
      .setHostKey(config.unico.sdkConfig.hostKey)

    const unicoCameraBuilder = new UnicoCheckBuilder()

    const unicoTheme = new UnicoThemeBuilder()
      .setColorSilhouetteSuccess('#11b048')
      .setColorSilhouetteError('#D50000')
      .setColorSilhouetteNeutral('#fcfcfc')
      .setBackgroundColor('#dff1f5')
      .setColorText(config.unico.style.primaryColor)
      .setBackgroundColorComponents(config.unico.style.primaryColor)
      .setColorTextComponents('#dff1f5')
      .setBackgroundColorButtons(config.unico.style.primaryColor)
      .setColorTextButtons('#dff1f5')
      .setBackgroundColorBoxMessage('#fff')
      .setColorTextBoxMessage('#000')
      .setHtmlPopupLoading(
        `<div style="position: absolute; top: 45%; right: 50%; transform:
          translate(50%, -50%); z-index: 10; text-align: center; height: 100%;">Carregando...</div>`
      )
      .build()

    console.log("shouldSetUATEnvironment ?", this.shouldSetUATEnvironment(config.unico.sdkConfig))
    if (this.shouldSetUATEnvironment(config.unico.sdkConfig)) {
      console.log("CAI NO setUATEnvironment")
      unicoCameraBuilder.setEnvironment(SDKEnvironmentTypes.UAT)
    }

    unicoCameraBuilder.setModelsPath(config.unico.sdkConfig.modelsPath).setResourceDirectory(config.unico.sdkConfig.resourcesPath)
    unicoCameraBuilder.setTheme(unicoTheme)

    console.log("unicoCameraBuilder", unicoCameraBuilder)

    const unicoCamera = unicoCameraBuilder.build()

    console.log("unicoCamera", unicoCamera)

    this.camera = unicoCamera.prepareSelfieCamera(unicoConfig, SelfieCameraTypes.SMART)
  }

  private shouldSetUATEnvironment(unicoConfig: UnicoSDKConfig): boolean {
    return !!unicoConfig.uatEnvironment
  }
}

export default UnicoSDKService
